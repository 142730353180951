import React from "react"
import { PayPalButton } from "react-paypal-button-v2"

export default function PayPal() {
  // useEffect(()=>{
  //     paypal.buttons({
  //         createOrder:(data,actions,error)=>{
  //             return actions.order.create({
  //                 intent: "CAPTURE",
  //                 purchase_units:{
  //                     description: "Your donation is completed",
  //                     amount:{
  //                         currency_code: "USD",
  //                         value: 45.34
  //                     }
  //                 }
  //             })
  //         },
  //         onApprove: async (data, actions) => {
  //             const order = await (actions.order.capture());
  //             console.log(order);
  //         },
  //         onError: (error)=>{
  //             console.log(error);
  //         }
  //     }).render(paypal.current)
  // },[])
  return (
    <div className="container text-center mt-5">
      <PayPalButton
        //amount="0.01"
        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        onSuccess={(details, data) => {
          alert("Transaction completed by " + details.payer.name.given_name)
          return
          // OPTIONAL: Call your server to save the transaction
          //   return fetch("/paypal-transaction-complete", {
          //     method: "post",
          //     body: JSON.stringify({
          //       orderId: data.orderID
          //     })
          //   });
        }}
        options={{
          clientId:
            "AeoEzS0a9dZs90scEJeYhnBFUyW9XHswBLZR8rTnF4yNGZpWzZLQM8KSAIROg5VZSc62sMnuAHy3nqpe",
        }}
      />
    </div>
  )
}
